<template>
      <v-app class>
        <div>
        <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
        </div>
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Job Area</h1>
              </div>
              <v-form ref="jobArea" v-model="valid">
                <v-row no-gutters>
                  <v-col cols="12">
                    <TextField v-model="formData.Name" :label="'Name'" />
                  </v-col>
                  <v-col cols="12">
                    <TextField v-model="formData.Name" :type="'Number'" :label="'Amount'" />
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end">

                      <Button
                      :label="'Submit'"
                      :btnType="'Submit'"
                      @onClick="onSave"
                      :disabled="!valid"
                      :isLoading="isSavingUser"
                      class="mr-4"
                    />

                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'

import { CREATE_JOBTITLE } from '@/store/action-type'

import AlertMixin from '@/mixins/AlertMixin'
import ErrorMixin from '@/mixins/ErrorMixin'
export default {
  components: {
    TextField,
    Button

  },
  mixins: [AlertMixin, ErrorMixin],
  data () {
    return {
      search: '',
      formData: {
        Name: '',
        Action: 'New',
        Status: 0,
        SalaryAmount: ''
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: ''
    }
  },
  computed: {},
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.isSavingUser = true

      this.$store
        .dispatch(CREATE_JOBTITLE, this.formData)
        .then(() => {
          this.showAlertMessage('Operation successful', 'success')
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Operation failed', 'error')
        })
        .finally(() => {
          this.isSavingUser = false
          this.$refs.jobArea.reset()
        })
    },
    onReset () {
      this.$refs.jobArea.reset()
    }
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
