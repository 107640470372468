<script>

import { ServerValidationError } from '@/utils/errors'

export default {
  data () {
    return {
      errors: []
    }
  },
  methods: {
    handleError (error) {
      if (error instanceof ServerValidationError) {
        var data = {}
        this.errors = [...data.errorList]
      }
      this.showErrorMessage(error.message)
    }
  }
}; </script>
